import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import '../style/styles.css'
import Image from 'next/image'
export default function FourthComponent() {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  const data = [
    {
      id: 1,
      title: 'Pause and remove payments '
    },
    {
      id: 2,
      title: 'Change schedules'
    },
    {
      id: 2,
      title: 'Edit amounts or downgrade packages '
    }
  ]
  return (
    <Box
      w={{ xl: '1300px', base: 'full' }}
      mx="auto"
      px={{ base: '2rem', md: '4rem', lg: '6rem' }}
      pt={{ lg: 20, base: 20 }}
      data-aos="fade-up"
      data-aos-duration="1000">
      <Flex
        align={'center'}
        justifyContent="space-between"
        direction={{ lg: 'row', base: 'column-reverse' }}>
        <Box>
          <Image src="/images/payment.png" width={620} height={600} alt="payment" />
        </Box>
        <Flex direction={'column'} gap={8}>
          <Box lineHeight={'shorter'}>
            <h1 className="take-charge">
              <span style={{ fontWeight: 'lighter', fontStyle: 'italic' }}> Take charge </span> of
              your <br />
              payment setups
            </h1>
          </Box>

          <Text
            maxW={{ sm: '500px', base: 'full' }}
            fontSize={{ base: '16px', md: '18px' }}
            textAlign={{ lg: 'start', base: 'center' }}>
            Customise your payment schedule with weekly, monthly, or tailored intervals that suit
            your needs.
          </Text>
          <Flex
            direction={'column'}
            maxW={{ md: '500px', base: 'full' }}
            mx={{ lg: '0', base: "'auto'" }}
            gap={6}>
            {data.map((item) => (
              <Flex key={item.id} align="center" gap={4}>
                <IoIosCheckmarkCircle size={'28px'} />
                <Text fontWeight={'semibold'} fontSize="18px">
                  {item.title}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
